import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

// home pages
const Home = lazy(() => import("./pages/home/HomeCosmetics"));

const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

const Contact = lazy(() => import("./pages/other/Contact"));


const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));

const NotFound = lazy(() => import("./pages/other/NotFound"));
const BePart = lazy(() => import("./pages/other/BePart"));
const Info = lazy(() => import("./pages/other/Info"));
const Faq = lazy(() => import("./pages/other/Faq"));

const OrderHandler = lazy(() => import('./pages/OrderComponent/OrderHandler'))

const App = (props) => {
  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json")
        }
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={Home}
                />

                {/* Homepages */}
                <Route
                  path={process.env.PUBLIC_URL + "/home"}
                  component={Home}
                />

                {/* Shop pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/shop"}
                  component={ShopGridFullWidth}
                />
               
                   <Route exact path="/success" component={OrderHandler} />
                <Route exact path="/pending" component={OrderHandler} />
                <Route exact path="/failure" component={OrderHandler} />

              

                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
               
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
           
                <Route
                  path={process.env.PUBLIC_URL + "/cart"}
                  component={Cart}
                />
             
                <Route
                  path={process.env.PUBLIC_URL + "/checkout"}
                  component={Checkout}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/be-part"}
                  component={BePart}
                />
                  <Route
                  path={process.env.PUBLIC_URL + "/faq"}
                  component={Faq}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/info"}
                  component={Info}
                />
              
    
                {/* <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                /> */}

                <Route exact component={Home} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
