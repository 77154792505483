export const checkIfHasVariants = (item) => item.sizes || item.textures

export const checkIfVariantsMissing = (item, selectedVariants) => {
  if(checkIfHasVariants(item)){
    return (
      (item.diffusersOption &&
        selectedVariants &&
        selectedVariants.diffusersOption)
    );
  }
  return checkIfHasVariants(item);
};